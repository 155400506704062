@use "sass:map";
@use 'vars' as vars;

html, body {
    height: inherit;
    min-height: 100vh;
}

body {
    font-family: vars.$font-fam-barlow;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
    font-family: vars.$font-fam-poppins;
}

div.body-wrapper-bg {
    height: 100%;
    background-image: url('../../static/upri_logo_bg.svg');
    background-color: transparent;
    background-position: 15vw center;
    background-size: auto 80%;
    background-repeat: no-repeat;
}

main {
    position: relative;
    padding-top: vars.$navbar-height;
    margin: 5vh vars.$main-margin-small 5vh vars.$main-margin-small;
    min-height: calc(100vh - vars.$navbar-height - vars.$footer-min-height);

    @media (min-width: 600px) {
        margin: 5vh vars.$main-margin-large 5vh vars.$main-margin-large;
    }

    & > section {
        margin: 1rem 0;
    }
}

footer {
    margin-top: auto;
    background-color: map.get(vars.$colors, "maroon");
    width: 100%;
    padding: 0.75rem 0;
    min-height: vars.$footer-min-height;

    p.copy-notice {
        width: 100%;
        color: white;
        text-align: center;
        margin: 0;
    }

    div.links {
        display: flex;
        column-gap: 0.5rem;
        justify-content: center;

        a:link, a:visited {
            color: map.get(vars.$colors, "light-green");
        }

        a:hover {
            color: map.get(vars.$colors, "white");
        }
    }
}

button, .button {
    background-color: map.get(vars.$colors, "green");
    border-radius: 20px;
    vertical-align: middle;

    & > i.material-icons {
        vertical-align: middle;
        margin: 0 0.25rem;
    }

    &:disabled {
        background-color: map.get(vars.$colors, "gray");
        color: map.get(vars.$colors, "white");
    }
}

button.view-more {
    width: 100%;
    padding: 0.5rem 0;
    margin: 0;
    border-radius: 10px;
    background-color: map.get(vars.$colors, "upri-green");

    &:disabled {
        background-color: transparent;
        color: map.get(vars.$colors, "green");
        border: 1px solid map.get(vars.$colors, "green");
    }
}

.card {
    border-radius: 1rem;
    padding: vars.$card-padding;
}

.card-float {
    @extend .card;
    box-shadow: vars.$elevate-z1;
    background-color: #f7f7f7;
}

div.policy-category-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.25rem;

    span.label {
        margin: 0;
        padding: 0.25rem 0.5rem;
        font-family: vars.$font-fam-barlow;
        font-size: 0.75em;
        border-radius: 15px;

        &.sector {
            background-color: map.get(vars.$colors, "light-green");
        }

        &.theme {
            background-color: map.get(vars.$colors, "maroon");
        }
    }
}

section#policy-sector-accordion {
    .item {
        &:not(&:nth-child(1)) {
            margin-top: 2vh;
        }

        a.header {
            width: 100%;
            padding: 1vh 1vw;
            margin: 0;
            text-decoration: none;
            cursor: pointer;
            color: black;
            background-color: map.get(vars.$colors, "light-gray");

            h3 {
                padding: 0;
            }

            .btn-show-detail {
                text-align: right;
                padding: 0;
                margin: 0;

                button {
                    margin: 0;
                    padding: 1vh 0.5vw;
                }
            }
        }

        .themes {
            margin-top: 2vh;
            margin-left: 0;
            width: 100%;
        }

        .themes .sector-marker.card {
            border: 0;
            box-shadow: vars.$elevate-z1;
        }
        
        .themes .list {
            padding: 0;
            margin-left: 2vw;

            @media (min-width: 600px) {
                & {
                    width: calc(50% - 2vw);
                }
            }
        }

        .themes .list .card {
            padding: 1vh 2vw;
            border-radius: 0;
            background: linear-gradient(to right, map.get(vars.$colors, "light-green") 50%, map.get(vars.$colors, "white") 50%);
            text-decoration: none;
            cursor: pointer;
            color: black;

            &:hover {
                opacity: 0.75;
            }

            span {
                padding: 0;
            }

            span:nth-child(1) {
                font-weight: bold;
                font-size: 1rem;
            }

            span:nth-child(2) {
                text-align: right;

                @media (max-width: 600px) {
                    & {
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    html, body {
        background-color: map.get(vars.$colors, "dark-gray");
        color: map.get(vars.$colors, "white");
    }
}
