@use "sass:map";
@use 'vars' as vars;

@media print {
    @page {
        margin: 2cm;
    }

    header {
        display: none;
    }

    main {
        margin: 0;
    }
    
    footer {
        display: none;
        background-color: transparent;
        border-top: 1px solid map.get(vars.$colors, "maroon");

        p.copy-notice {
            color: black;
            font-weight: bold;
        }

        div.links {
            display: none;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        page-break-after: avoid; // LEGACY
        break-after: avoid;
        page-break-inside: avoid; // LEGACY
        break-inside: avoid;
    }

    article {
        page-break-before: always; // LEGACY
        break-before: page;
    }

    ol, ul, dl {
        page-break-inside: avoid;
        break-inside: avoid; // LEGACY
    }

    div.policy-category-tags {
        span.label {
            padding: 0;
            font-family: vars.$font-fam-barlow;
            font-size: 1rem;

            &.sector:not(:last-child):after {
                content: ", ";
            }

            &.theme:not(:last-child)::after {
                content: ", ";
            }
        }
    }
}